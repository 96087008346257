import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // detect user language
    .init({
        resources: {
            en: {
                translation: {
                    "defaultPage_nav": {
                        "home": "Home",
                        "about": "About Us",
                        "services": "Services",
                        // "events": "Events",
                        "why": "Why Us",
                        "platform": "Platform",
                        "financed_projects": "Financed Projects",
                        "products": {
                            'productsMain': 'Products',
                            'datastate': 'DataSatate.ai',
                            'fortify': 'Fortify',
                            'menaproptech': 'Mena PropTech'
                        },
                        "events": {
                            'eventsMain': 'Events',
                            'convention': 'PropTech Convention',
                            'club': 'PropTech Club',
                        },
                        "blog": "Blogs",
                        "contact": "Contact"
                    },

                    "defaultPage_home": {
                        "main_heading": "Pakistan's 1st PropTech Facilitation Platform",
                        "subtext": {
                            'text1': 'Empower your Real Estate Journey with Innovation and Clarity through AI',
                            'text2': 'Propel Your Real Estate Business into the Future with Landtrack.pk',
                            'text3': "Invest Smartly with Landtrack.pk's Digital Solutions",
                            'text4': "Streamline Operations, Maximize Profits & Transform Your Real Estate Business"
                        },
                        "learn_btn": "Get In Touch",
                        "call_btn": "Call Now"
                    },

                    "defaultPage_info": {
                        "data": {
                            'heading': 'AI Data-Driven Insights',
                            'text': 'AI-powered insights for market trends, ROI projections, and risk assessments.'
                        },
                        "marketing": {
                            'heading': 'AI Marketing & Sales',
                            'text': 'Optimize your sales with tailored primary and secondary market campaigns.'
                        },
                        "conference": {
                            'heading': 'Conferences',
                            'text': 'Annual Property Technology conference, connecting innovators and industry leaders.'
                        },
                        "training": {
                            'heading': 'PropTech Exports Training',
                            'text': 'Certified & Placement guaranteed trainings to stay ahead in the Real Estate Tech industry.'
                        }
                    },

                    "defaultPage_about": {
                        "main_heading": "Who We Are?",
                        "subtext": "Imagine a world without uncertainty, an environment fueled by advanced technological solutions, informed decision-making, and real estate success stories",
                        "highlight": "that is the Landtrack.pk difference!",
                        "pointers": {
                            'point 1': 'We utilize modern technology and AI for transparency.',
                            'point 2': 'We leverage data insights for informed decison making.',
                            'point 3': 'We Empower Home-owners & buyers with due diligence.',
                            'point 4': 'Supporting you from your first investment to a perfect home.'
                        }
                    },

                    "defaultPage_services": {
                        "main_heading": "We Support You Throughout Your Journey",
                        "subtext": {
                            'b2b': 'Enabling Real Estate Ease for Builders & Developers with Advanced PropTech Solutions',
                            'b2c': 'Enabling Real Estate Ease for first-time Buyers or Investors with Advanced PropTech Solutions'
                        },
                        "service": {
                            "datastate": {
                                "heading": "Data Analytics and Feasibility Studies",
                                "text": "powered by",
                                "button": "Explore DataState.ai",
                                "points": [
                                    "Data-driven insights to optimise project planning and resource allocation.",
                                    "Customised reports detailing potential ROI, market demand, and the competition.",
                                    "Comprehensive market analysis and risk assessment using advanced AI algorithms."
                                ]
                            },
                            "marketing": {
                                'heading': ' Effective AI Marketing and Sales',
                                'text': 'powered by',
                                'button': 'Explore More',
                                'points': [
                                    'Data-driven insights to optimise project planning and resource allocation.',
                                    'Customised reports detailing potential ROI, market demand, and the competition.',
                                    'Comprehensive market analysis and risk assessment using advanced AI algorithms.'
                                ]
                            },
                            "neura": {
                                "heading": "AI-Powered Real Estate Tools",
                                "text": "powered by",
                                "button": "Explore More",
                                "points": [
                                    "Real-time conversational support for property inquiries featuring voice and video functionalities.",
                                    "Seamless interactions with voice commands, appointment scheduling, and automated customer support.",
                                    "AI-powered voice agents for multilingual customer interactions, data storage, and enhanced service delivery."
                                ]
                            },
                            "fortify": {
                                "heading": "Streamline Your Property Management",
                                "text": "powered by",
                                "button": "Explore Fortify",
                                "points": [
                                    "Manage customer relationships and business administration with comprehensive CRM tools.",
                                    "Leverage WhatsApp and social media integrations for seamless communication and lead management.",
                                    "Efficiently handle client documents, campaign management, and maintain a structured contact book."
                                ]
                            },
                            "arvr": {
                                "heading": "AR/VR Solutions for Immersive Property Showcases",
                                "text": "powered by",
                                "button": "Explore More",
                                "points": [
                                    "High-quality virtual tours to boost buyer engagement and remote viewing.",
                                    "Integration with existing marketing channels for a seamless user experience.",
                                    "Customised AR features to showcase key property aspects and improvement potential."
                                ]
                            },
                            "legal": {
                                "heading": "Legal Expertise for Smooth Transactions",
                                "text": "powered by",
                                "button": "Explore Lawtrack",
                                "points": [
                                    "In-depth contract review and drafting to ensure compliance with local laws.",
                                    "Expert negotiation and dispute resolution services to protect your interests.",
                                    "Guidance on regulatory requirements and due diligence for property transactions."
                                ]
                            },
                            "techzone": {
                                "heading": "Tech Zone Consultation for Future-Proof Development",
                                "text": "powered by",
                                "button": "Explore More",
                                "points": [
                                    "Strategic advice on incorporating the latest technology trends into your projects.",
                                    "Solutions for smart infrastructure and IoT integration to enhance property value.",
                                    "Recommendations for sustainable and energy-efficient technologies for long-term benefits."
                                ]
                            },
                            "reit": {
                                "heading": "REIT Investment Advisory to Unlock Funding",
                                "text": "powered by",
                                "button": "Explore More",
                                "points": [
                                    "Tailored investment strategies to attract REIT funding for your projects.",
                                    "Connections with a network of potential investors and financial institutions.",
                                    "Comprehensive financial analysis and risk assessment to secure optimal funding terms."
                                ]
                            },
                            "datastateb2c": {
                                "heading": "Data Analytics and Insights for Informed Decisioncs",
                                "text": "powered by",
                                "button": "Explore DataState.ai",
                                "points": [
                                    'Detailed property market trends and pricing analysis to guide your investments.',
                                    'Predictive analytics to forecast property value changes and market conditions.',
                                    'Customised reports on neighbourhood demographics, crime rates, and school ratings.'
                                ]
                            },
                            "realtors": {
                                "heading": "Personalised Consultation from Experienced Professionals",
                                "text": "powered by",
                                "button": "Explore RealtorsTrack",
                                "points": [
                                    'One-on-one sessions to understand your specific needs and preferences.',
                                    'Expert advice on property selection, financing options, and negotiation strategies.',
                                    'Assistance throughout the entire buying or selling process for a smooth transaction.'
                                ]
                            }
                        }
                    },

                    "defaultPage_event": {
                        "main_heading": "PropTech & SmarTech Convention",
                        "subtext": {
                            'text1': 'Every year, Landtrack.pk organises the Proptech Convention— an industry event that facilitates:',
                            'bold': 'Investors, Developers, Real Estate Professionals',
                            'text2': 'with the striking opportunities for:'
                        },
                        "pointers": [
                            'Improving Property Ventures',
                            'Knowledge Sharing',
                            'Exchanging Innovative ideas',
                            'Collaboration'
                        ],
                        "button": "Read More",
                        "numbers": [
                            'ATTENDEES', 'SPEAKERS', 'SPONSORS', 'EXHIBITORS', 'B2B MEETINGS'
                        ]
                    },

                    "defaultPage_club": {
                        "sub_heading": "Let’s Gather For A Change!",
                        "main_heading": "Building A Real Estate Community",
                        "text": {
                            'text 1': 'Join our dynamic ',
                            'bold': 'PropTech Club',
                            'text 2': ', ask questions, receive expert advice, or interact with developers, investors, and property seekers.'
                        },
                        "pointers": [
                            'Exclusive Networking',
                            'Expert Advice',
                            'Interactive QnAs',
                            'Industry Updates'
                        ],
                        "highlight": "Join hands with industry leaders, gain valuable insights, and stay ahead of market trends.",
                        "button": "Join Here",
                    },

                    "defaultPage_why": {
                        "sub_heading": "We Are Beyond the Ordinary",
                        "main_heading": "What Makes Us Unique!",
                        "why_card": {
                            "tech": {
                                "heading": "On-ground Experience",
                                "text": "With 6+ years in real estate and PropTech, we connect stakeholders and help navigate market trends and innovations.",
                            },
                            "knowledge": {
                                "heading": "Data-Driven Aproach",
                                "text": "We leverage data analytics, AI, and immersive tech like AR/VR, enabling informed, faster, and smarter decisions.",
                            },
                            "facilitation": {
                                "heading": "Industry Network",
                                "text": "Through our PropTech Club, we connect industry leaders, fostering collaboration and innovation for growth.",
                            },
                            "data": {
                                "heading": "Annual PropTech Convention",
                                "text": "Our annual PropTech Convention gathers 1,000+ leaders, driving collaboration and showcasing the latest PropTech innovations.",
                            },
                            "consultation": {
                                "heading": "Award-winning Products",
                                "text": "Landtrack.pk is recognized by the HBL P@SHA ICT Awards in Big Data Category, underscoring our excellence in data-driven solutions.",
                            }
                        }
                    },

                    "defaultPage_test": {
                        "sub_heading": "Testimonials",
                        "main_heading": "What Our Clients Say",
                        "test_card": {
                            "raheel": {
                                "name": "Raheel Rinch",
                                "desig": "MD Aman Builders & Developers",
                                "text": "Kudos to the organizers, Landtrack.pk and Ecommerce gateway! This was a much needed event to revitalize Pakistan’s real estate sector."
                            },
                            "fayyaz": {
                                "name": "Fayyaz Ilyas",
                                "desig": "Director Falaknaz Group",
                                "text": "The entire Landtrack team deserves our gratitude for their efforts. Thanks to everyone for creating such a great event."
                            },
                            "aman": {
                                "name": "Amanullah Panjwani",
                                "desig": "CEO PIBSAT",
                                "text": "I have been teaching real estate for 35 years, but I still learned a lot from this event."
                            },
                            "arshad": {
                                "name": "Arshad Awan",
                                "desig": "CEO Homy Properties",
                                "text": "I enjoyed this a lot, hearing about the PropTech revolution. Thanks to Landtrack.pk for raising our hopes for continued success."
                            },
                            "button": "Watch"
                        },
                        "button": "Watch All"
                    },

                    "defaultPage_blog": {
                        "sub_heading": "Insights To Empower You",
                        "main_heading": "Read Our Blogs",
                        "blog_card": {
                            "blog1": {
                                "heading": "Aman Golf View: Ready-To-Move Luxury Apartments",
                                "text": "Located in the vibrant Jinnah Avenue, the heart of Karachi, Aman Golf View Apartments redefine luxury living. Situated near Malir Cantt and just moments away from Jinnah International Airport, this high-rise development offers unparalleled convenience and sophistication, making it a covenient address for discerning residents.",
                            },
                            "blog2": {
                                "heading": "International PropTech Partnerships",
                                "text": "The creation of Reportage Empire Pakistan marks a milestone in foreign investment in Pakistan’s real estate.",
                            },
                            "blog3": {
                                "heading": "Embracing Digital Real Estate in Pakistan",
                                "text": "The landscape of digital real estate is evolving rapidly, fueled by the integration of advanced digital technologies around the world. In Dubai, Samana Developers recently announced a significant digital transformation in partnership with Oracle,",
                            },
                            "button": "Read More"
                        },
                        "button": "Read All Blogs"
                    },

                    "defaultPage_awards": {
                        "main_heading": "Our Achievements",
                    },

                    "defaultPage_clientele": {
                        "main_heading": "Our Clients",
                    },

                    "defaultPage_affiliate": {
                        "sub_heading": "Be A Landtrack.pk Affiliate!",
                        "main_heading": "Join Our Affiliate Program",
                        "text": "Are you interested in spreading the word about Landtrack.pk while making commissions? Join our affiliate programme!",
                        "highlight": "Register on our website and complete a quick registration form.",
                        "button": "Register Here"
                    },

                    "defaultPage_contact": {
                        "sub_heading": "Ready to Supercharge Your Real Estate Efforts?",
                        "main_heading": "Get In Touch With Us Today!",
                        "text": "You may be an ambitious investor seeking information-driven insight or an eager buyer who needs expert advice.",
                        "highlight": "We have what you Want!"
                    },

                    "defaultPage_footer": {
                        "text": "Landtrack.pk is Pakistan's first PropTech facilitation platform, leveraging cutting-edge technology to deliver data-driven insights for the real estate market. It aims to educate and empower buyers, enabling informed decisions with tools like CRM, AR/VR, and analytics. Targeting a wide range of stakeholders, Landtrack.pk revolutionizes real estate through digital transformation.",
                        "contact": {
                            'heading': 'Contact Us'
                        },
                        "social": {
                            'heading': 'Lets Get Social'
                        }
                    }
                }
            },
            ur: {
                translation: {
                    "defaultPage_nav": {
                        "home": "ہوم",
                        "about": "ہمارے بارے میں",
                        "services": "خدمات",
                        // "events": "تقریبات",
                        "why": "ہم کیوں",
                        "platform": "مارکیٹ",
                        "products": {
                            "productsMain": "مصنوعات",
                            "datastate": "ڈیٹا اسٹیٹ اے آئی",
                            "fortify": "فورٹیفائی",
                            "menaproptech": "مینا پروپٹیک"
                        },
                        "events": {
                            'eventsMain': 'تقریبات',
                            'convention': 'پراپٹیک کنونشن',
                            'club': 'پراپٹیک کلب',
                        },
                        "blog": "بلاگز",
                        "contact": "رابطہ کریں"
                    },

                    "defaultPage_home": {
                        "main_heading": "پاکستان کا پہلا پراپرٹی ٹیکنالوجی پلیٹ فارم",
                        "subtext": {
                            "text1": "اپنے رئیل اسٹیٹ سفر کو جدت اور وضاحت کے ساتھ اے آئی کے ذریعے بااختیار بنائیں",
                            "text2": "اپنے رئیل اسٹیٹ کاروبار کو لینڈ ٹریک کے ساتھ مستقبل میں لے جائیں",
                            "text3": " لینڈ ٹریک کے ڈیجیٹل حلوں کے ساتھ ہوشیاری سے سرمایہ کاری کریں",
                            "text4": "آپریشنز کو آسان بنائیں، منافع کو زیادہ سے زیادہ کریں اور اپنے کاروبار کو تبدیل کریں"
                        },
                        "learn_btn": "رابطہ کریں",
                        "call_btn": "ابھی کال کریں"
                    },

                    "defaultPage_info": {
                        "data": {
                            "heading": "اے آئی کے ذریعے معلومات",
                            "text": "مارکیٹ کے رجحانات، ROI کے تخمینوں، اور خطرات کے اندازوں کے لئے اے آئی سے طاقتور بصیرتیں۔"
                        },
                        "marketing": {
                            "heading": "اے آئی مارکیٹنگ اور سیلز",
                            "text": "پرائمری اور سیکنڈری مارکیٹ مہمات کو موزوں بنا کر اپنی فروخت کو بہتر بنائیں۔"
                        },
                        "conference": {
                            "heading": "کانفرنسیں",
                            "text": "سالانہ پراپرٹی ٹیکنالوجی کانفرنس، جو جدت پسندوں اور صنعت کے رہنماؤں کو مربوط کرتی ہے۔"
                        },
                        "training": {
                            "heading": "پراپٹیک ایکسپورٹس ٹریننگ",
                            "text": "رئیل اسٹیٹ ٹیک انڈسٹری میں آگے رہنے کے لئے تصدیق شدہ اور جگہ دینے کی گارنٹی والی تربیتیں۔"
                        }
                    },

                    "defaultPage_about": {
                        "main_heading": "ہم کون ہیں؟",
                        "subtext": "ایک ایسی دنیا کا تصور کریں جہاں کوئی ابہام نہ ہو، ایک ماحول جو جدید ٹیکنالوجیکل حل، آگاہ فیصلہ سازی، اور ریئل اسٹیٹ کی کامیاب کہانیوں سے معمور ہو",
                        "highlight": "! کا فرق ہے Landtrack.pk یہی",
                        "pointers": {
                            "point 1": "کا استعمال شفافیت کے لیے کرتے ہیں۔ AI ہم جدید ",
                            "point 2": "ہم معلوماتی فیصلہ سازی کے لیے ڈیٹا بصیرت کا استعمال کرتے ہیں۔",
                            "point 3": "ہم ہوم اونرز اور خریداروں کو مناسب چھان بین کے ذریعے بااختیار بناتے ہیں۔",
                            "point 4": "آپ کی پہلی سرمایہ کاری سے لے کر ایک کامل گھر تک آپ کی مدد کرتے ہیں۔"
                        }
                    },

                    "defaultPage_services": {
                        "main_heading": "آپ کے سفر کے دوران ہم آپ کی مدد کرتے ہیں",
                        "subtext": {
                            "b2b": "بلڈرز اور ڈویلپرز کے لیے جدید پراپٹیک حلوں کے ساتھ ریئل اسٹیٹ کو آسان بنانا",
                            "b2c": "پہلی بار خریداروں یا سرمایہ کاروں کے لیے جدید پراپٹیک حلوں کے ساتھ ریئل اسٹیٹ کو آسان بنانا"
                        },
                        "service": {
                            "datastate": {
                                "heading": "ڈیٹا تجزیات اور فیزیبلٹی اسٹڈیز",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "منصوبہ بندی اور وسائل کی تقسیم کو بہتر بنانے کے لیے ڈیٹا سے چلنے والی بصیرتیں۔",
                                    "ممکنہ ROI، مارکیٹ ڈیمانڈ، اور مقابلہ کی تفصیلات والی حسب ضرورت رپورٹس۔",
                                    "جدید AI الگورتھمز کا استعمال کرتے ہوئے جامع مارکیٹ تجزیہ اور رسک اسیسمنٹ۔"
                                ]
                            },
                            "marketing": {
                                "heading": 'مؤثر مارکیٹنگ اور فروخت AI',
                                "text": 'طاقتور بذریعہ',
                                "button": 'مزید جانیں',
                                "points": [
                                    'منصوبہ بندی اور وسائل کی تقسیم کو بہتر بنانے کے لیے ڈیٹا سے چلنے والی بصیرتیں۔',
                                    'ممکنہ ROI، مارکیٹ ڈیمانڈ، اور مقابلہ کی تفصیلات والی حسب ضرورت رپورٹس۔',
                                    'جدید AI الگورتھمز کا استعمال کرتے ہوئے جامع مارکیٹ تجزیہ اور رسک اسیسمنٹ۔'
                                ]
                            },
                            "neura": {
                                "heading": "اے آئی ریئل اسٹیٹ ٹولز",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "پراپرٹی انکوائری کے لیے حقیقی وقت کا بات چیت پر مبنی تعاون، جس میں آواز اور ویڈیو کی سہولت شامل ہے۔",
                                    "آواز کے احکامات، ملاقات کے شیڈول، اور خودکار کسٹمر سپورٹ کے ساتھ بغیر کسی رکاوٹ کے تعامل۔",
                                    "کثیر لسانی کسٹمر انٹریکشن، ڈیٹا اسٹوریج، اور بہتر سروس ڈیلیوری کے لیے اے آئی سے چلنے والے وائس ایجنٹس۔"
                                ]
                            },
                            "fortify": {
                                "heading": "اپنی پراپرٹی مینجمنٹ کو بہتر بنائیں",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "ٹولز کے ساتھ کسٹمر تعلقات اور کاروباری انتظام کو منظم کریں۔ CRM جامع ",
                                    "ہموار مواصلت اور لیڈ مینجمنٹ کے لیے واٹس ایپ اور سوشل میڈیا انضمام سے فائدہ اٹھائیں۔",
                                    "کلائنٹ دستاویزات، مہم کا انتظام، اور منظم کنٹیکٹ بُک کو مؤثر طریقے سے سنبھالیں۔"
                                ]
                            },
                            "arvr": {
                                "heading": "AR/VR جائیداد کی نمائش کے لیے",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "خریدار کی مشغولیت اور دور سے دیکھنے کو بڑھانے کے لیے اعلیٰ معیار کی ورچوئل ٹورز۔",
                                    "بلاتعطل صارف تجربے کے لیے موجودہ مارکیٹنگ چینلز کے ساتھ انضمام۔",
                                    "پراپرٹی کے اہم پہلوؤں اور بہتری کی صلاحیت کو دکھانے کے لیے حسب ضرورت AR خصوصیات۔"
                                ]
                            },
                            "legal": {
                                "heading": "ہموار لین دین کے لیے قانونی مہارت",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "مقامی قوانین کی تعمیل کو یقینی بنانے کے لیے گہرائی سے معاہدہ جائزہ اور مسودہ سازی۔",
                                    "آپ کے مفادات کی حفاظت کے لیے ماہر مذاکرات اور تنازعات کا حل۔",
                                    "پراپرٹی لین دین کے لیے ریگولیٹری ضروریات اور دیانتداری کے لیے رہنمائی۔"
                                ]
                            },
                            "techzone": {
                                "heading": "مستقبل پروف ڈویلپمنٹ کے لیے ٹیک زون مشاورت",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "اپنے منصوبوں میں جدید ٹیکنالوجی کے رجحانات کو شامل کرنے کے لیے حکمت عملی کی مشاورت۔",
                                    "پراپرٹی کی قدر بڑھانے کے لیے اسمارٹ انفراسٹرکچر اور IoT انضمام کے حل۔",
                                    "طویل مدتی فوائد کے لیے پائیدار اور توانائی سے مؤثر ٹیکنالوجیز کی سفارشات۔"
                                ]
                            },
                            "reit": {
                                "heading": "سرمایہ کاری مشاورت کے ذریعے فنڈنگ کو آزاد کرنا REIT",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "آپ کے منصوبوں کے لیے REIT فنڈنگ کو راغب کرنے کے لیے حسب ضرورت سرمایہ کاری حکمت عملیاں۔",
                                    "ممکنہ سرمایہ کاروں اور مالیاتی اداروں کے نیٹ ورک کے ساتھ روابط۔",
                                    "بہترین فنڈنگ شرائط حاصل کرنے کے لیے جامع مالی تجزیہ اور رسک اسیسمنٹ۔"
                                ]
                            },
                            "datastateb2c": {
                                "heading": "معلوماتی فیصلوں کے لیے ڈیٹا تجزیہ اور بصیرت",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "آپ کی سرمایہ کاری کی رہنمائی کے لیے تفصیلی پراپرٹی مارکیٹ کے رجحانات اور قیمتوں کا تجزیہ۔",
                                    "پراپرٹی کی قیمتوں اور مارکیٹ کی شرائط میں تبدیلیوں کی پیشین گوئی کرنے والے تجزیات۔",
                                    "پڑوس کی آبادی، جرائم کی شرح، اور اسکول کی درجہ بندی پر حسب ضرورت رپورٹس۔"
                                ]
                            },
                            "realtors": {
                                "heading": "تجربہ کار پیشہ ور افراد سے ذاتی مشاورت",
                                "text": "طاقتور بذریعہ",
                                "button": "مزید جانیں",
                                "points": [
                                    "آپ کی خاص ضروریات اور ترجیحات کو سمجھنے کے لیے ون-ٹو-ون سیشنز۔",
                                    "پراپرٹی کے انتخاب، مالی اختیارات، اور مذاکراتی حکمت عملیوں پر ماہر مشورہ۔",
                                    "خریدنے یا بیچنے کے پورے عمل کے دوران معاونت کے لئے ہموار لین دین۔"
                                ]
                            }
                        }
                    },

                    "defaultPage_event": {
                        "main_heading": "پراپٹیک اور اسمارٹ ٹیک کنونشن",
                        "subtext": {
                            'text1': 'ہر سال، لینڈٹریک.پی کے پراپٹیک کنونشن کا اہتمام کرتا ہے— ایک صنعتی ایونٹ جو فراہم کرتا ہے:',
                            'bold': 'سرمایہ کاروں، ڈیولپرز، رئیل اسٹیٹ پروفیشنلز',
                            'text2': 'کے لئے نمایاں مواقع:'
                        },
                        "pointers": [
                            'پراپرٹی وینچرز میں بہتری',
                            'علم کی شراکت داری',
                            'جدت طرازی کے خیالات کا تبادلہ',
                            'تعاون'
                        ],
                        "button": "مزید پڑھیں",
                        "numbers": [
                            'شرکاء', 'مقررین', 'اسپانسرز', 'نمائش کنندگان', 'B2B میٹنگز'
                        ]
                    },

                    "defaultPage_club": {
                        "sub_heading": "تبدیلی کے لیے اکٹھے ہوں!",
                        "main_heading": "ریئل اسٹیٹ کمیونٹی کی تعمیر",
                        "text": {
                            'text 1': ' ہمارے متحرک',
                            'bold': ' پراپٹیک کلب',
                            'text 2': ' میں شامل ہوں، سوالات پوچھیں، ماہرانہ مشورے حاصل کریں، یا ڈیولپرز، سرمایہ کاروں اور پراپرٹی متلاشیوں کے ساتھ بات چیت کریں۔'
                        },
                        "pointers": [
                            'خصوصی نیٹ ورکنگ',
                            'ماہر مشورہ',
                            'بات چیت کے لیے سوال و جواب',
                            'صنعتی اپڈیٹس'
                        ],
                        "highlight": "صنعتی رہنماؤں کے ساتھ ہاتھ ملا کر قیمتی بصیرت حاصل کریں اور مارکیٹ کے رجحانات سے آگے رہیں۔",
                        "button": "یہاں شامل ہوں"
                    },

                    "defaultPage_why": {
                        "sub_heading": "ہم معمولی سے ہٹ کر ہیں",
                        "main_heading": "ہمیں منفرد کیا بناتا ہے!",
                        "why_card": {
                            "tech": {
                                "heading": "ٹیکنالوجی سے منسلک حل",
                                "text": "ہم جدید ٹیکنالوجی سے چلنے والے حلوں میں مہارت رکھتے ہیں جو حریفوں کے مہیا کردہ حلوں سے بہتر ہیں۔"
                            },
                            "knowledge": {
                                "heading": "گہرا مقامی علم",
                                "text": "ہماری ٹیم پاکستانی ریئل اسٹیٹ مارکیٹ کے مخصوص بصیرت اور مشورہ فراہم کرتی ہے۔"
                            },
                            "facilitation": {
                                "heading": "آغاز سے اختتام تک کی سہولت",
                                "text": "قابلیت کے مطالعات اور مارکیٹنگ سے لے کر مالی مشورہ اور قانونی خدمات تک، ہم ریئل اسٹیٹ کے ہر پہلو کو کور کرتے ہیں۔"
                            },
                            "data": {
                                "heading": "ڈیٹا سے فیصلے",
                                "text": "جدید ڈیٹا تجزیہ اور AI ٹولز کا استعمال کرتے ہوئے، ہم آگاہی پر مبنی فیصلہ سازی کے لئے عملی بصیرت اور پیشن گوئیاں فراہم کرتے ہیں۔"
                            },
                            "consultation": {
                                "heading": "ذاتی مشاورت",
                                "text": "ہم زیادہ تجربہ کار ریئل اسٹیٹ ماہرین سے ذاتی مشورہ کے ساتھ گاہک کی اطمینان کو ترجیح دیتے ہیں۔"
                            }
                        }
                    },

                    "defaultPage_test": {
                        "sub_heading": "مواقف",
                        "main_heading": "ہمارے کلائنٹس کیا کہتے ہیں",
                        "test_card": {
                            "raheel": {
                                "name": "راحیل رنچ",
                                "desig": "ایم ڈی امان بلڈرز اینڈ ڈیولپرز",
                                "text": "منتظمین کو خراج تحسین، لینڈٹریک اور ای کامرس گیٹ وے! پاکستان کے رئیل اسٹیٹ سیکٹر کو دوبارہ زندہ کرنے کے لیے یہ ایونٹ بہت ضروری تھا۔"
                            },
                            "fayyaz": {
                                "name": "فیاض الیاس",
                                "desig": "ڈائریکٹر فلکناز گروپ",
                                "text": "پوری لینڈٹریک ٹیم کا شکریہ ادا کرنا چاہیے ان کی کوششوں کے لیے۔ ہر کسی کا شکریہ اس شاندار ایونٹ بنانے کے لیے۔"
                            },
                            "aman": {
                                "name": "امان اللہ پنجوانی",
                                "desig": "سی ای او پیبسٹ",
                                "text": "میں نے 35 سال سے رئیل اسٹیٹ پڑھایا ہے، لیکن اس ایونٹ سے مجھے بہت کچھ سیکھنے کو ملا۔"
                            },
                            "arshad": {
                                "name": "ارشد اعوان",
                                "desig": "سی ای او ہومی پراپرٹیز",
                                "text": "مجھے اس سے بہت لطف آیا، پروپٹیک انقلاب کے بارے میں سن کر۔ لینڈٹریک کا شکریہ ہماری امیدوں کو بلند کرنے کے لیے۔"
                            },
                            "button": "ویڈیو دیکھیں"
                        },
                        "button": "سب دیکھیں"
                    },

                    "defaultPage_blog": {
                        "sub_heading": "اپنی معلومات میں اضافہ کریں",
                        "main_heading": "ہمارے بلاگز پڑھیں",
                        "blog_card": {
                            "blog1": {
                                "heading": "بجٹ 2024 اور اس کا رئیل اسٹیٹ پر اثر:",
                                "text": "پاکستان کا بجٹ 2024 براہ راست رئیل اسٹیٹ سیکٹر کو متاثر کرتا ہے، جس میں ٹیکس پالیسیوں، انفراسٹرکچر کی ترقی اور سرمایہ کاری کی ترغیبات میں تبدیلیاں شامل ہیں۔"
                            },
                            "blog2": {
                                "heading": "پاکستان میں ڈیجیٹل ریئل اسٹیٹ کو اپنانا",
                                "text": "ڈیجیٹل ریئل اسٹیٹ کی تزئین و آرائش تیزی سے ہو رہی ہے، جسے دنیا بھر میں جدید ڈیجیٹل ٹیکنالوجیز کے انضمام سے تقویت مل رہی ہے۔ دبئی میں، سمانہ ڈویلپرز"
                            },
                            "blog3": {
                                "heading": "رئیل اسٹیٹ میں خواتین کو بااختیار بنانا",
                                "text": "یہ بلاگ رئیل اسٹیٹ میں خواتین کے کلیدی کردار کو اجاگر کرتا ہے، ایک متحرک پینل بحث سے متاثر ہو کر جس کا عنوان ہے خواتین کا رئیل اسٹیٹ انڈسٹری میں کردار"
                            },
                            "button": "مکمل پڑھیں"
                        },
                        "button": "تمام بلاگز پڑھیں"
                    },

                    "defaultPage_awards": {
                        "main_heading": "حاصل کردہ اعزازات",
                    },

                    "defaultPage_clientele": {
                        "main_heading": "ہمارے کلائنٹس",
                    },

                    "defaultPage_affiliate": {
                        "sub_heading": "! کے افیلیٹ بنیں Landtrack.pk",
                        "main_heading": "ہمارے افیلیٹ پروگرام میں شامل ہوں",
                        "text": "کیا آپ لینڈٹریک کے بارے میں بات پھیلانے میں دلچسپی رکھتے ہیں جبکہ کمیشن بھی کمانا چاہتے ہیں؟ ہمارے افیلیٹ پروگرام میں شامل ہوں!",
                        "highlight": "ہماری ویب سائٹ پر رجسٹر کریں اور ایک مختصر رجسٹریشن فارم مکمل کریں۔",
                        "button": "یہاں رجسٹر کریں"
                    },

                    "defaultPage_contact": {
                        "sub_heading": "اپنی رئیل اسٹیٹ کوششوں کو سپرچارج کرنے کے لئے تیار ہیں؟",
                        "main_heading": "آج ہی ہم سے رابطہ کریں!",
                        "text": "آپ ایک مہتواکانکشی سرمایہ کار ہو سکتے ہیں جو معلوماتی بصیرت کی تلاش میں ہے یا ایک بے چین خریدار جسے ماہر مشورہ کی ضرورت ہے۔",
                        "highlight": "ہمارے پاس وہ ہے جو آپ چاہتے ہیں!"
                    },

                    "defaultPage_footer": {
                        "text": "پاکستان میں ایک پروپ ٹیک پلیٹ فارم ہے جو جدید ٹیکنالوجی کا استعمال کرتے ہوئے رئیل اسٹیٹ مارکیٹ کے لیے ڈیٹا پر مبنی بصیرت فراہم کرتا ہے۔ اس کا مقصد خریداروں کو تعلیم دینا اور بااختیار بنانا ہے، جس سے وہ CRM، AR/VR، اور تجزیاتی ٹولز کی مدد سے آگاہ فیصلے کر سکیں۔ یہ مختلف اسٹیک ہولڈرز کو نشانہ بناتا ہے۔ Landtrack.pk",
                        "contact": {
                            "heading": "ہم سے رابطہ کریں"
                        },
                        "social": {
                            "heading": "ہمیں فالو کریں"
                        }
                    }
                }
            }
        },
        fallbackLng: "en",  // Set fallback language to Urdu
        lng: "en",          // Set initial language to Urdu
        interpolation: {
            escapeValue: false // React already safes from XSS
        }
    });

export default i18n;